<template>
    <div class="">
        <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">地圖全覽</h2>
        </div>
        <!-- BEGIN: HTML Table Data -->
        <div class="intro-y box p-5 mt-5 ">
            <div class="">
                <iframe
                    class=" w-full h-70vh"
                    width="100%"
                    src="https://www.penghuqgis.net.tw/qgis/index.html"
                    frameborder="0"
                    scrolling="no"
                >
                </iframe>
            </div>
        </div>
    </div>
</template>

<!-- <script lang="ts">
/* eslint-disable */
// import CloudFun, { defineComponent } from "@cloudfun/core";

</script> -->

<style lang="scss">
    .h-70vh {
        height: 78vh;
    }
</style>
